import { OTA_STATUS } from '@/utils/helpers';
import deviceService from '../services/devices';

const initialState = {
  devices: {},
  devicesById: {},
  checkboxFilters: {
    lights: true,
    switches: true,
    sensors: true,
    healthIssues: false,
  },
  savedSortByCategory: {
    lights: null,
    switches: null,
    sensors: true,
  },
  productRowsByRoom: [],
  projectDevices: {},
  projectDevicesById: {},
};

const deviceCategories = ['lights', 'switches', 'sensors'];

const actions = {
  async listDevices({ commit }, { uuid, roomId }) {
    const { data } = await deviceService.listDevices({ uuid, roomId });
    commit('listDevices', { uuid, roomId, data });
  },
  async getDevice({ commit }, { uuid, roomId, deviceId }) {
    const { data } = await deviceService.getDevice({ uuid, roomId, deviceId });
    commit('getDevice', {
      uuid, roomId, deviceId, data,
    });
  },
  async updateDevice(ctx, {
    uuid, roomId, deviceId, data,
  }) {
    await deviceService.updateDevice({
      uuid, roomId, deviceId, data,
    });
  },
  async deleteDevice(ctx, { uuid, roomId, deviceId }) {
    await deviceService.deleteDevice({ uuid, roomId, deviceId });
  },
  async bulkDeleteDevices(ctx, { uuid, roomId, deviceUUIDs }) {
    await deviceService.bulkDeleteDevices({ uuid, roomId, deviceUUIDs });
  },
  async moveDevices(ctx, { uuid, roomId, data }) {
    await deviceService.moveDevices({ uuid, roomId, data });
  },
  async copySwitch(ctx, { uuid, roomId, data }) {
    await deviceService.copySwitch({ uuid, roomId, data });
  },
  checkboxFiltersUpdate({ commit }, filters) {
    commit('checkboxFiltersUpdate', filters);
  },
  savedSortByCategoryUpdate({ commit }, categories) {
    commit('savedSortByCategoryUpdate', categories);
  },
  productRowsByRoomUpdate({ commit }, rooms) {
    commit('productRowsByRoomUpdate', rooms);
  },
  checkboxFiltersReset({ commit }) {
    commit('checkboxFiltersReset');
  },
  async listProjectDevices({ commit }, { uuid, timeout }) {
    const { data } = await deviceService.listProjectDevices({ uuid, timeout });
    commit('listProjectDevices', { uuid, data });
  },
  async updateDeviceFirmware(ctx, { uuid, data, all }) {
    await deviceService.updateDeviceFirmware({ uuid, data, all }).catch((error) => {
      // console.log(error);
      // ctx.dispatch('app/setError', {
      //  title: i18n.t('firmwareUpdateInProgress'),
      //  message: i18n.t('firmwareUpdateInProgressMessage'),
      // }, { root: true });
      throw error;
    });
  },
};

const mutations = {
  listDevices(state, payload) {
    const emptyState = { lights: [], switches: [], sensors: [] };
    const devices = { ...state.devices };
    devices[payload.uuid] = state.devices[payload.uuid] || {};
    devices[payload.uuid][payload.roomId] = { ...emptyState, ...payload.data };
    state.devices = devices;
    state.devicesById = deviceCategories.map((cat) => {
      const devicesByCat = payload.data[cat] || [];
      return devicesByCat
        .reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
    }).reduce((acc, item) => ({ ...acc, ...item }), {});
  },
  getDevice(state, payload) {
    state.devicesById = { ...state.devicesById, [payload.deviceId]: payload.data };
  },
  checkboxFiltersUpdate: (state, filters) => {
    state.checkboxFilters = filters;
  },
  savedSortByCategoryUpdate: (state, categories) => {
    state.savedSortByCategory = categories;
  },
  productRowsByRoomUpdate: (state, rooms) => {
    state.productRowsByRoom = rooms;
  },
  checkboxFiltersReset: (state) => {
    state.checkboxFilters = {
      lights: true,
      switches: true,
      sensors: true,
      healthIssues: false,
    };
  },
  listProjectDevices: (state, payload) => {
    const devices = { ...state.projectDevices };
    devices[payload.uuid] = payload.data;
    state.projectDevices = devices;
    payload.data.forEach((device) => {
      state.projectDevicesById = { ...state.projectDevicesById, [device.deviceUuid]: device };
    });
  },
};

const getters = {
  getLightsByProject: (state) => (uuid) => {
    if (state.devices[uuid]) {
      const result = { };
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const roomId in state.devices[uuid]) {
        result[roomId] = state.devices[uuid][roomId].lights || [];
      }
      return result;
    }
    return {};
  },
  getDevicesByRoom: (state, actGetters, rootState, rootGetters) => (uuid, roomId) => {
    const emptyState = { lights: [], switches: [], sensors: [] };
    const result = { ...emptyState, ...((state.devices[uuid] || {})[roomId] || {}) };
    Object.keys(result).forEach((category) => {
      result[category] = result[category]
        .map((device) => ({
          ...device,
          hasIssue: !!(rootGetters['projects/getHealthEntries'](uuid).byId[device.uuid] || []).length,
          ean: device.product.ean || '',
        }));
    });
    return result;
  },
  getDeviceById: (state) => (deviceId) => state.devicesById[deviceId] || { product: {} },
  getProjectDevices: (state) => (uuid) => (state.projectDevices[uuid] || [])
    .map((device) => {
      device.otaStatusInfo = device.otaStatusInfo || {};
      return device;
    }),
  getProductUpdates: (state) => (uuid) => {
    const projectDevices = (state.projectDevices[uuid] || [])
      . map((device) => {
        device.otaStatusInfo = device.otaStatusInfo || {};
        return device;
      });
    const categories = ['gateways', 'lights', 'sensors', 'switches'];
    const devicesByCat = categories.reduce((acc, categoryKey) => {
      const devices = projectDevices.filter((device) => device.category === categoryKey)
        .filter((device) => !!device.macAddress);
      if (!devices.length) {
        return acc;
      }
      return ({ ...acc, [categoryKey]: devices });
    }, {});

    return Object.entries(devicesByCat).reduce((acc, [categoryKey, devices]) => {
      acc[categoryKey] = acc[categoryKey] || {
        products: [],
        allUpdated: true,
        errored: false,
        updating: false,
        waitForUpdate: false,
      };
      devices.forEach((device) => {
        let product = acc[categoryKey].products.find((p) => p.productUuid === device.productUuid);
        if (!product) {
          product = {
            productUuid: device.productUuid,
            productName: device.productName,
            normalizedVersion: device.normalizedFwVersion,
            fwVersion: device.fwVersion,
            updatedCount: 0,
            totalCount: 0,
            devices: [],
            errored: false,
            updating: false,
            waitForUpdate: false,
          };
          acc[categoryKey].products.push(product);
        }

        // CHECK errored
        product.errored = product.errored || device.otaStatusInfo?.otaStatus === OTA_STATUS.ERRORED;
        acc[categoryKey].errored = acc[categoryKey].errored || product.errored;

        // CHECK updating
        product.updating = product.updating || device.otaStatusInfo?.otaStatus === OTA_STATUS.UPDATING;
        acc[categoryKey].updating = acc[categoryKey].updating || product.updating;

        // CHECK waitForUpdate
        product.waitForUpdate = product.waitForUpdate || device.otaStatusInfo?.otaStatus === OTA_STATUS.WAITING;
        acc[categoryKey].waitForUpdate = acc[categoryKey].waitForUpdate || product.waitForUpdate;

        product.totalCount += 1;
        const newFirmware = (device.firmwareUpdates || [])[0];
        product.devices.push({ device, newFirmware });
        if (device.hasNewFirmware) {
          product.targetFwVersion = newFirmware.displayVersion;
          if (device.normalizedFwVersion < product.normalizedVersion) {
            product.normalizedVersion = device.normalizedFwVersion;
            product.fwVersion = device.fwVersion;
          }
          acc[categoryKey].allUpdated = false;
        } else {
          product.updatedCount += 1;
        }
      });

      return acc;
    }, {});
  },
  getProjectDeviceById: (state) => (deviceId) => state.projectDevicesById[deviceId],
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
