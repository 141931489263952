import config from '@/config';
import localeService from '../services/locale';
import i18n from '../../i18n';
import userService from '../services/user';
import { logEvent } from '../../firebase';

const initialState = {
  locale: 'en',
  countryCode: null,
  countries: [],
  country: null,
};

const actions = {
  changeLocale({ commit, state }, locale) {
    const targetLocale = locale || state.locale;
    i18n.locale = targetLocale;
    i18n._vm.$root.locale = targetLocale;
    commit('changeLocale', targetLocale);
    logEvent('change_locale', { locale: targetLocale });
  },
  async changeCountry({ commit, rootState, dispatch }, countryCode) {
    if (rootState.locale.countryCode === countryCode) {
      return;
    }

    commit('changeCountry', { countryCode });
    logEvent('change_country', { country_code: countryCode });
    if (rootState.auth.userId) {
      await userService.updateUser({
        userUUID: rootState.auth.userId,
        data: { countryId: rootState.locale.country.id },
      });
      await dispatch('user/getProfile', {}, { root: true });
    }
  },
  async getLegalDocument({ state }, { type, link = false, serverLink = false }) {
    const { data } = await localeService.getLegalDocument({
      locale: state.locale,
      countryCode: state.countryCode,
      type,
      link,
      serverLink,
    });
    if (link || serverLink) {
      return data.link;
    }
    // const charset = ctx.state.locale === 'fr' ? 'utf-8' : 'iso-8859-2';
    const encoder = new TextDecoder('utf-8');
    return encoder.decode(data);
  },
  async getCountries({ commit }) {
    const { data } = await localeService.getCountries();
    commit('getCountries', data);
  },
};

const mutations = {
  changeLocale: (state, locale) => {
    state.locale = locale;
  },
  changeCountry: (state, { countryCode, countryId }) => {
    state.country = state.countries
      .find((country) => country.id === countryId || country.countryCode === countryCode);
    state.countryCode = state.country.countryCode;
  },
  getCountries: (state, data) => {
    state.countries = data;
    if (state.countryCode && data.length > 0) {
      // update selected country at login
      state.country = state.countries.find((country) => country.countryCode === state.countryCode);
    }
  },
};

const getters = {
  getLocale: (state) => state.locale,
  getCountryCode: (state) => state.countryCode,
  hasCountryCode: (state) => !!state.countryCode,
  getCountry: (state) => state.country || {},
  getLocales: () => [
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'de',
      name: 'Deutsch',
    },
    {
      code: 'fr',
      name: 'Français',
    },
    {
      code: 'es',
      name: 'Español',
    },
    {
      code: 'it',
      name: 'Italiano',
    },
    {
      code: 'nl',
      name: 'Nederlands',
    },
    {
      code: 'pt',
      name: 'Português',
    },
    /* {
      code: 'da',
      name: 'Dansk',
    },
    {
      code: 'fi',
      name: 'Suomi',
    },
    {
      code: 'nb',
      name: 'Norsk',
    },
    {
      code: 'sv',
      name: 'Svenska',
    }, */
  ],
  getCountryList: (state) => state.countries
    .map((country) => ({
      id: country.id,
      code: country.countryCode,
      name: i18n.t(`countries.${country.name.replace(/\s/g, '')}`),
      region: country.backend.backendIdentifier,
      businessRegionName: country.businessRegionName,
    })),
  getApiUrl: (state) => {
    const { webAPIHost } = state?.country?.backend || { webAPIHost: '' };
    let protocol = 'https';
    if (webAPIHost.includes('localhost')) {
      protocol = 'http';
    }
    return (state.country ? `${protocol}://${webAPIHost}/api/1/` : config.API_URL);
  },
  getWsUrl: (state) => {
    const { webAPIHost } = state?.country?.backend || { webAPIHost: '' };
    let protocol = 'wss';
    if (webAPIHost.includes('localhost')) {
      protocol = 'ws';
    }
    return (state.country ? `${protocol}://${webAPIHost}/api/1/websocket/connect?client=planningtool` : config.WS_URL);
  },
  getRegion: (state) => state.country.backend.backendIdentifier || '',
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
