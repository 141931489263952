const proxyCache = new WeakMap();
function createDeepOnChangeProxy(target, onChange) {
  return new Proxy(target, {
    get(_target, property) {
      const item = _target[property];
      if (!proxyCache.has(_target)) {
        proxyCache.set(_target, { path: 'stateRoot' });
      }
      if (item && typeof item === 'object') {
        if (proxyCache.has(item)) {
          const { proxy } = proxyCache.get(item);
          return proxy;
        }
        const proxy = createDeepOnChangeProxy(item, onChange);
        const targetPath = proxyCache.get(_target).path;
        proxyCache.set(item, { proxy, path: `${targetPath}.${property}` });
        return proxy;
      }
      return item;
    },
    set(_target, property, newValue) {
      const targetPath = proxyCache.get(_target)?.path || 'stateRoot';
      if (_target[property] === newValue) {
        return true;
      }
      _target[property] = newValue;
      onChange(`${targetPath}.${property}`, newValue);
      return true;
    },
  });
}

export default class BehaviourState {
  constructor(projectId, roomId, groupId) {
    this.projectId = projectId;
    this.roomId = roomId;

    if (!this.roomId && !this.projectId) {
      throw new Error('ProjectId or roomId is required to create a BehaviourState');
    }

    this.groupId = groupId;
    this._state = {
      defaultBehaviour: 'manual', // manual, semi-automatic, automatic
      autoOff: {
        enabled: false,
        delayDimEnabled: true,
        delayDim: undefined,
        levelDim: undefined,
        delay: undefined,
      },
      autoOn: {
        enabled: false,
        level: undefined,
      },
      dlh: {
        enabled: false,
        regulationValue: 100,
        geometryFactor: 3,
      },
      hcl: {
        enabled: false,
        hclMaxDim: undefined,
      },
      sensors: [],
    };

    this.originalState = {};
    this.originalStatePayloadJSON = '{}';
    this.originalRulePayloadJSON = '{}';
    this.originalSensorsDataJSON = '{}';
    this._snapshotState();
    this.undefinedRule = false;
  }

  get isRoomRule() {
    return !this.groupId;
  }

  get state() {
    return createDeepOnChangeProxy(this._state, (path) => {
      if (path?.endsWith('defaultBehaviour')) {
        this._state.autoOff.enabled = this._state.defaultBehaviour !== 'manual';
        this._state.autoOn.enabled = this._state.defaultBehaviour === 'automatic';
      }
    });
  }

  // true, if anything has changed since the original state
  get hasChangedSinceOriginal() {
    const payload = this.getUpdatePayload(this._state);
    return JSON.stringify(payload) !== this.originalStatePayloadJSON;
  }

  // true, if sensors settings (occupancy, illumination) have changed since the original state
  get hasSensorsChanged() {
    return JSON.stringify(this._state.sensors) !== this.originalSensorsDataJSON;
  }

  // true, if the rule settings have changed since the original state
  get hasRuleChanged() {
    const payload = this.getUpdatePayload(this._state);
    return JSON.stringify(payload.rulePayload) !== this.originalRulePayloadJSON;
  }

  _snapshotState() {
    const payload = this.getUpdatePayload(this._state);
    this.originalState = JSON.parse(JSON.stringify(this._state));
    this.originalStatePayloadJSON = JSON.stringify(payload);
    this.originalRulePayloadJSON = JSON.stringify(payload.rulePayload);
    this.originalSensorsDataJSON = JSON.stringify(this._state.sensors);
  }

  createStateByRule(rule, opts = {}) {
    const { defaultBehaviour, dlh } = opts;
    const state = JSON.parse(JSON.stringify(this._state));

    // eslint-disable-next-line no-nested-ternary
    const calculatedDefaultBehaviour = rule?.actions?.value?.['auto-off'] ? rule?.actions?.value?.['auto-on'] ? 'automatic' : 'semi-automatic' : 'manual';
    state.defaultBehaviour = defaultBehaviour || calculatedDefaultBehaviour;

    state.autoOff.enabled = defaultBehaviour !== 'manual';
    state.autoOff.delayDim = rule?.actions?.value?.['delay-dim'] || 600;
    state.autoOff.delayDimEnabled = rule?.actions?.value?.['auto-off'] ? rule?.actions?.value?.['delay-dim'] !== undefined : true;
    state.autoOff.levelDim = rule?.actions?.value?.['level-dim'] || 30;
    state.autoOff.delay = rule?.actions?.value?.delay || 300;

    state.autoOn.enabled = defaultBehaviour === 'automatic';
    state.autoOn.level = rule?.actions?.value?.level || 100;

    state.dlh.enabled = rule?.actions?.value?.['dlh-enabled'] || false;
    state.dlh.regulationValue = dlh?.['regulation-value'] || 100;
    state.dlh.geometryFactor = dlh?.['geometry-factor'] || 3;

    state.hcl.enabled = rule?.actions?.value?.['hcl-enabled'] || false;
    state.hcl.hclMaxDim = rule?.actions?.value?.['hcl-max-dim'] || 100;

    return state;
  }

  loadSensors(sensors) {
    this._state.sensors = sensors.filter((sensor) => {
      if (this.isRoomRule) {
        return !sensor.groups?.length;
      }
      return sensor.groups?.map((g) => g.uuid).includes(this.groupId);
    }).map((sensor) => ({
      uuid: sensor.uuid,
      sequenceNumber: sensor.sequenceNumber,
      name: sensor.product?.name,
      description: sensor.description,
      motionCapability: sensor.product?.capabilities?.motion,
      lightCapability: sensor.product?.capabilities?.light,
      // Properties to set:
      occupancy: sensor.occupancy === null ? true : !!sensor.occupancy,
      illumination: sensor.illumination === null ? true : !!sensor.illumination,
    }));

    this._snapshotState();
  }

  loadDefaultRule(rule, opts) {
    if (!rule) {
      this.undefinedRule = true;
    }

    if (rule?.room && rule.room !== this.roomId) {
      console.warn('Loading default rule for different room');
      this.roomId = rule.room;
    }

    if (rule?.actions?.target?.uuid && rule.actions.target.type === 'group' && rule.actions.target.uuid !== this.groupId) {
      console.warn('Loading default rule for different group');
      this.groupId = rule.actions.target.uuid;
    }

    this._state = this.createStateByRule(rule, opts);
    this._snapshotState();
  }

  resetState(part) {
    const originalState = JSON.parse(JSON.stringify(this.originalState));
    switch (part) {
      case 'autoOff':
        this.state.autoOff = originalState.autoOff;
        break;
      case 'autoOn':
        this.state.autoOn = originalState.autoOn;
        break;
      case 'dlh':
        this.state.dlh = originalState.dlh;
        break;
      case 'hcl':
        this.state.hcl = originalState.hcl;
        break;
      case 'all':
        this._state = JSON.parse(JSON.stringify(originalState));
        break;
      default:
        break;
    }
  }

  getUpdatePayload(state) {
    if (!state) {
      state = this._state;
    }

    let dlh = state.dlh.enabled ? {
      uuid: this.isRoomRule ? this.roomId : this.groupId,
      'regulation-value': state.dlh.regulationValue,
      'geometry-factor': state.dlh.geometryFactor,
    } : undefined;

    if (dlh) {
      if (this.isRoomRule) { // Is dlh a Group Rule? Use array format
        dlh = { room: dlh };
      } else {
        dlh = { groups: [dlh] };
      }
    }

    const data = {
      actions: {
        target: {
          type: this.isRoomRule ? 'room' : 'group',
          uuid: this.isRoomRule ? this.roomId : this.groupId,
        },
        value: {
          'auto-off': state.defaultBehaviour !== 'manual',
          'delay-dim': state.defaultBehaviour !== 'manual' && Number.isInteger(state.autoOff.delayDim) && state.autoOff.delayDimEnabled ? state.autoOff.delayDim : undefined,
          'level-dim': state.defaultBehaviour !== 'manual' && Number.isInteger(state.autoOff.delayDim) && state.autoOff.delayDimEnabled ? state.autoOff.levelDim : undefined,
          delay: state.defaultBehaviour !== 'manual' ? state.autoOff.delay : undefined,

          'auto-on': state.defaultBehaviour === 'automatic',
          level: state.defaultBehaviour === 'automatic' ? (state.autoOn.level || 100) : undefined,

          'dlh-enabled': state.dlh.enabled,

          'hcl-enabled': state.hcl.enabled,
          'hcl-max-dim': state.hcl.enabled ? state.hcl.hclMaxDim : undefined,
        },
      },
      dlh,
      defaultBehaviour: state.defaultBehaviour,
    };

    const payload = {
      projectId: this.projectId,
      roomId: this.roomId,
      data,
    };

    if (!this.isRoomRule) {
      payload.groupId = this.groupId;
    }

    const sensorData = {};
    this._state.sensors.forEach((sensor) => {
      sensorData[sensor.uuid] = {
        occupancy: sensor.occupancy,
        illumination: sensor.illumination,
      };
    });

    return { rulePayload: payload, sensorData };
  }
}
