import Vue from 'vue';
import { ClickBus } from '@/bus';

Vue.directive('outside-click', {
  bind(el, binding, vnode) {
    el.handleOutsideClick = (event) => {
      if (event.clickedElement === el) {
        return;
      }
      ClickBus.$emit('outside-click', vnode.context._uid);
    };

    document.addEventListener('click', el.handleOutsideClick);

    el.addEventListener('click', (event) => {
      event.clickedElement = el;
    }, true);
  },
  unbind(el) {
    document.removeEventListener('click', el.handleOutsideClick, true);
  },
});
