import { serializeDeviceActions, deserializeDeviceActions } from '../utils/helpers';

const BUTTON_TYPES = { PUSH_BUTTON: 'push button', ROCKER: 'rocker', EXPERT: 'expert' };
const EVENT_TYPES = { PRESSED: 'pressed', HOLD_PRESSED: 'hold_pressed', RELEASED: 'released' };

export default {
  computed: {
    productName() {
      return this.device?.product?.name;
    },
    macAddress() {
      return this.device?.mac || 'N/A';
    },
    numberOfChannels() {
      return this.device?.product?.numberOfChannels || 'N/A';
    },
    buttonType() {
      return this.device?.buttonType || 'N/A';
    },
    category() {
      return this.device?.product?.category || 'N/A';
    },
    isSwitch() {
      return this.category === 'switches';
    },
    isSensor() {
      return this.category === 'sensors';
    },
    isLight() {
      return this.category === 'lights';
    },
    isLightOrSensor() {
      return this.isLight || this.isSensor;
    },
    deviceGroups() {
      return (this.device?.groups || []).map((group) => group.uuid);
    },
    longPress() {
      return this.device?.product?.capabilities?.longPress;
    },
    occupancyCapable() {
      return this.device?.product?.capabilities?.motion;
    },
    illuminationCapable() {
      return this.device?.product?.capabilities?.light;
    },
    greenPower() {
      return this.device?.product?.capabilities?.greenPower;
    },
    productImage() {
      const pictures = this.device?.product?.attachments?.pictures || [];
      if (pictures.length) {
        return pictures[0].urls.large;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/placeholders/placeholder_switches.svg');
    },
    BUTTON_TYPES() {
      return BUTTON_TYPES;
    },
    EVENT_TYPES() {
      if (!this.longPress) {
        // eslint-disable-next-line no-unused-vars
        const { HOLD_PRESSED, ...rest } = EVENT_TYPES;
        return rest;
      }
      return EVENT_TYPES;
    },
    switchEventsByType() {
      const removeHoldPressed = (obj) => {
        // eslint-disable-next-line no-unused-vars
        const { [this.EVENT_TYPES.HOLD_PRESSED]: _, ...rest } = obj;
        return rest;
      };
      return (buttonType) => {
        const baseMap = {
          [this.BUTTON_TYPES.ROCKER]: {
            [this.EVENT_TYPES.HOLD_PRESSED]: this.$t('roomDevicesEventRockerHoldPressed'),
            [this.EVENT_TYPES.RELEASED]: this.$t('roomDevicesEventRockerReleased'),
          },
          [this.BUTTON_TYPES.PUSH_BUTTON]: {
            [this.EVENT_TYPES.PRESSED]: this.$t('roomDevicesEventPBCPressed'),
            [this.EVENT_TYPES.HOLD_PRESSED]: this.$t('roomDevicesEventPBCHoldPressed'),
          },
          [this.BUTTON_TYPES.EXPERT]: {
            [this.EVENT_TYPES.PRESSED]: this.$t('roomDevicesEventExpertPressed'),
            [this.EVENT_TYPES.HOLD_PRESSED]: this.$t('roomDevicesEventExpertHoldPressed'),
            [this.EVENT_TYPES.RELEASED]: this.$t('roomDevicesEventExpertReleased'),
          },
        };
        const selectedMap = baseMap[buttonType || this.BUTTON_TYPES.EXPERT];
        if (!this.longPress) {
          return removeHoldPressed(selectedMap);
        }
        return selectedMap;
      };
    },
    buttonTypes() {
      if (this.greenPower) {
        const types = [{ value: this.BUTTON_TYPES.PUSH_BUTTON, text: 'Push Button' }];
        if (this.longPress) {
          types.push({ value: this.BUTTON_TYPES.EXPERT, text: 'Expert' });
        }
        return types;
      }
      return [
        { value: this.BUTTON_TYPES.ROCKER, text: 'Rocker' },
        { value: this.BUTTON_TYPES.PUSH_BUTTON, text: 'Push Button' },
        { value: this.BUTTON_TYPES.EXPERT, text: 'Expert' },
      ];
    },
    serializedButtons() {
      return (activeLayouts = 4) => {
        const buttons = serializeDeviceActions(this.device?.actions, this.device?.channelMappings);
        return Object.values(buttons).map((button) => {
          if (button.number > activeLayouts) {
            return {
              number: button.number, target: {}, events: {}, inactive: true,
            };
          }
          return button;
        });
      };
    },
  },
  methods: {
    deserializeButtons(buttons) {
      return deserializeDeviceActions(buttons, this.device?.channelMappings);
    },
    getButtonTypeName(buttonType) {
      return this.buttonTypes.find((type) => type.value === buttonType)?.text;
    },
    isSwitchConfigured() {
      return !!this.device.switchLayout;
    },
    calcTypeAndLayout(device) {
      const layout = device.switchLayout || device.actions.reduce((acc, action) => {
        if (action.button > acc) {
          acc = action.button;
        }
        return acc;
      }, 1);

      return {
        buttonType: device.buttonType || 'push button',
        layout,
      };
    },
  },
};
