/* global axios */

const listDevices = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/devices`,
});

const getDevice = async ({ uuid, roomId, deviceId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/devices/${deviceId}`,
});

const updateDevice = async ({
  uuid, roomId, deviceId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/devices/${deviceId}`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_room_device' }),
});

const deleteDevice = async ({ uuid, roomId, deviceId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/devices/${deviceId}`,
  lock: { uuid },
  logEvent: () => ({ eventName: 'delete_device' }),
});

const bulkDeleteDevices = async ({ uuid, roomId, deviceUUIDs }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/devices`,
  lock: { uuid },
  data: { deviceUUIDs },
  logEvent: () => ({ eventName: 'bulk_delete_devices_from_room' }),
});

const moveDevices = async ({ uuid, roomId, data }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/rooms/${roomId}/devices/move`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'move_device' }),
});

const copySwitch = async ({ uuid, roomId, data }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/rooms/${roomId}/switches/copy`,
  lock: { uuid },
  data,
});

const listProjectDevices = async ({ uuid, timeout }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/devices`,
  skipGlobalErrorHandling: true,
  timeout,
});

const updateDeviceFirmware = async ({ uuid, data, all }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/devices/update-firmwares`,
  lock: { uuid },
  data,
  skipGlobalErrorHandling: true,
  logEvent: () => ({ eventName: all ? 'update_all_devices' : 'update_device_firmware' }),
});

export default {
  listDevices,
  getDevice,
  updateDevice,
  deleteDevice,
  bulkDeleteDevices,
  moveDevices,
  copySwitch,
  listProjectDevices,
  updateDeviceFirmware,
};
