import appService from '../services/app';

const initialState = {
  error: null,
  projectUpdatedState: {
    updated: false,
    data: null,
  },
  appData: {},
  floorplanOpened: false,
  floorplanViewerReload: false,
  tabsWithProject: {},
  selectedView: 'list',
  productSearch: '',
  productSort: 'name-asc',
  hiddenSettings: false,
};

const actions = {
  floorplanUpdate({ commit }, opened) {
    commit('floorplanUpdate', opened);
  },
  floorplanViewerUpdate({ commit }, status) {
    commit('floorplanViewerUpdate', status);
  },
  async getAppData({ commit }) {
    const { data } = await appService.getAppData();
    commit('getAppData', data);
  },
  trackTab({ commit }, { tabId, projectId }) {
    commit('trackTab', { tabId, projectId });
  },
  // use: dispatch('app/setError', null, { root: true });
  setError({ commit }, error) {
    commit('setError', { error });
  },
  clearError({ commit }) {
    commit('setError', { error: null });
  },
  changeListView({ commit }, val) {
    commit('changeListView', val);
  },
  changeProductSearch({ commit }, val) {
    commit('changeProductSearch', val);
  },
  changeProductSort({ commit }, val) {
    commit('changeProductSort', val);
  },
  resetProductFilter({ commit }) {
    commit('resetProductFilter');
  },
  projectUpdatedEvent({ commit }, { updated, data }) {
    commit('projectUpdatedEvent', { updated, data });
  },
  toggleHiddenSettings({ commit }, visibility = false) {
    commit('toggleHiddenSettings', visibility);
  },
};

const mutations = {
  getAppData(state, data) {
    state.appData = data;
  },
  trackTab: (state, { tabId, projectId }) => {
    const tabs = { ...state.tabsWithProject };
    if (!projectId) {
      delete tabs[tabId];
      state.tabsWithProject = { ...tabs };
      return;
    }
    state.tabsWithProject = { ...tabs, [tabId]: projectId };
  },
  floorplanUpdate: (state, opened) => {
    state.floorplanOpened = opened;
  },
  floorplanViewerUpdate: (state, status) => {
    state.floorplanViewerReload = status;
  },
  setError: (state, data) => {
    const { error } = data;
    const errorMessage = {
      hasError: !!error,
      title: '',
      message: '',
      type: 'error',
    };
    if (!errorMessage.hasError) {
      state.error = errorMessage;
      return;
    }
    if (error.errorMessage === 'Validation error') {
      errorMessage.title = 'Validation error';
      errorMessage.message = error.errorDetails[0].message;
      if (error.errorDetails?.some((err) => err?.type === 'tooManyGreenPowerDevices')) {
        errorMessage.title = 'Room limit reached';
        errorMessage.message = 'The selected device(s) cannot be added because a room can only contain up to 5 Green Power switches at a time. Please consider removing some old devices or adding the new one(s) to another room.';
      }
    } else if (error.errorMessage === 'unlock-failed') {
      errorMessage.title = 'Unlock failed';
      errorMessage.message = 'Project unlock failed. Another user is working on the project or your session does not have a valid lock token';
    } else if (error.errorMessage === 'project-version-not-supported') {
      errorMessage.title = 'Firmware update required';
      errorMessage.message = `In order to use this feature in the project, you need to update the controller to firmware version ${error.errorData.version} or newer.`;
      errorMessage.type = 'firmware-update';
    } else if (error.errorMessage) {
      errorMessage.title = error.errorMessage;
      // TODO translate these codes
      errorMessage.message = error.errorCode;
    } else if (error.message && error.title) {
      errorMessage.title = error.title;
      errorMessage.message = error.message;
    }
    state.error = errorMessage;
  },
  changeListView(state, view) {
    state.selectedView = view;
  },
  changeProductSearch(state, value) {
    state.productSearch = value;
  },
  changeProductSort(state, value) {
    state.productSort = value;
  },
  resetProductFilter(state) {
    state.productSearch = '';
    state.productSort = 'name-asc';
  },
  projectUpdatedEvent(state, { updated, data }) {
    state.projectUpdatedState = { updated, data };
  },
  toggleHiddenSettings(state, visibility) {
    state.hiddenSettings = visibility;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
