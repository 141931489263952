/* global axios */

const getProjectConfig = async ({ uuid, wip }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/config`,
  params: { wip },
});

const getProjectConfigRevision = async ({ uuid, revision }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/config/revision/${revision}`,
});

const listRevisions = async ({ uuid }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/config/revisions`,
});

const restoreRevision = async ({ uuid, revision }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/config/revision/${revision}/restore`,
  lock: { uuid },
  data: {},
  logEvent: () => ({ eventName: 'restore_revision' }),
});

const setZigbeeChannel = async ({ uuid, channel }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/config/zigbee/`,
  lock: { uuid },
  data: { channel },
});

export default {
  getProjectConfig,
  getProjectConfigRevision,
  listRevisions,
  restoreRevision,
  setZigbeeChannel,
};
