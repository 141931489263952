import ruleService from '../services/rules';

const initialState = {
  rules: {},
  rulesById: {},
  defaultRuleByRoom: {},
  defaultRuleByGroup: {},
  defaultRulesLoaded: false,
};

const actions = {
  async listRules({ commit }, { uuid, roomId }) {
    const { data } = await ruleService.listRules({ uuid, roomId });
    commit('listRules', { uuid, roomId, data });
  },
  async getDefaultRule({ commit }, { uuid, roomId }) {
    const { data } = await ruleService.getDefaultRule({ uuid, roomId });
    commit('getDefaultRule', { data, roomId });
  },
  async updateDefaultRule({ commit }, {
    uuid, roomId, data,
  }) {
    const response = await ruleService.updateDefaultRule({
      uuid, roomId, data,
    });
    commit('getDefaultRule', { data: response.data, roomId });
  },
  async getDefaultGroupRule({ commit }, { uuid, roomId, groupId }) {
    const { data } = await ruleService.getDefaultGroupRule({ uuid, roomId, groupId });
    commit('getDefaultGroupRule', { data, roomId, groupId });
  },
  async updateDefaultGroupRule({ commit }, {
    uuid, roomId, groupId, data,
  }) {
    const response = await ruleService.updateDefaultGroupRule({
      uuid, roomId, groupId, data,
    });
    commit('getDefaultGroupRule', { data: response.data, roomId, groupId });
  },
  async getRule({ commit }, { uuid, roomId, ruleId }) {
    const { data } = await ruleService.getRule({ uuid, roomId, ruleId });
    commit('getRule', { data, ruleId });
  },
  async createRule(ctx, { uuid, roomId, data }) {
    const response = await ruleService.createRule({ uuid, roomId, data });
    return response.data;
  },
  async updateRule({ commit }, {
    uuid, roomId, ruleId, data,
  }) {
    const response = await ruleService.updateRule({
      uuid, roomId, ruleId, data,
    });
    commit('getRule', { data: response.data, ruleId });
  },
  async deleteRule(ctx, { uuid, roomId, ruleId }) {
    await ruleService.deleteRule({ uuid, roomId, ruleId });
  },
  async setRoomRules(ctx, {
    uuid, roomId, rules, sensors,
  }) {
    await ruleService.setRoomRules({
      uuid, roomId, rules, sensors,
    });
  },
};

const mutations = {
  listRules(state, payload) {
    const rules = { ...state.rules };
    rules[payload.uuid] = state.rules[payload.uuid] || {};
    rules[payload.uuid][payload.roomId] = payload.data;
    state.rules = rules;
    state.rulesById = payload.data.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  getRule(state, payload) {
    state.rulesById = { ...state.rulesById, [payload.ruleId]: payload.data };
  },
  getDefaultRule(state, payload) {
    let rule;
    if (payload.data?.uuid) {
      rule = payload.data;
    }
    state.defaultRuleByRoom = { ...state.defaultRuleByRoom, [payload.roomId]: rule };
  },
  getDefaultGroupRule(state, payload) {
    let rule;
    if (payload.data?.uuid) {
      rule = payload.data;
    }
    state.defaultRuleByGroup = { ...state.defaultRuleByGroup, [payload.groupId]: rule };
  },
};

const getters = {
  getRulesByRoom: (state, actGetters, rootState, rootGetters) => (uuid, roomId) => {
    if (state.rules[uuid]) {
      return (state.rules[uuid][roomId] || []).map((rule) => ({
        ...rule,
        values: rule.values || [],
        hasIssue: !!(rootGetters['projects/getHealthEntries'](uuid).byId[rule.uuid] || []).length,
      }));
    }
    return [];
  },
  getRuleById: (state) => (ruleId) => state.rulesById[ruleId] || {},
  getDefaultRuleByRoom: (state) => (roomUUID) => state.defaultRuleByRoom[roomUUID] || null,
  getDefaultRuleByGroup: (state) => (groupUUID) => state.defaultRuleByGroup[groupUUID] || null,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
